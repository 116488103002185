import React from 'react';
import BlogArticle from 'components/blog/blogArticle';
import { Post } from 'components/blog/content/styled';
import { authorRonald as author } from 'data/authors';
import img from 'img/blog/covers/website-speed.png';

import img1 from 'img/blog/website-speed/traffic.jpg';
import img2 from 'img/blog/website-speed/pagespeed-insights.png';
import img3 from 'img/blog/website-speed/stats.png';
import img4 from 'img/blog/website-speed/image-scale.png';
import img5 from 'img/blog/website-speed/wp.png';

const Content = () => {
  return (
    <Post>
      <p>
        At the risk of sounding like a crotchety old-timer, kids these days ain’t got no patience.
        Thanks to high-speed WiFi, 4G data plans across the globe and Amazon’s ubiquitizing of next
        day delivery, in 2020 one thing is clear: people don’t want to wait. With superfast
        broadband at home and data in our pockets, the web is becoming faster and more dynamic than
        ever.
      </p>

      <p>
        In a world where everything is speeding up, the challenge becomes keeping up - and as
        attention spans drop and patience thins, website loading speeds can become a huge barrier to
        reaching your customers. Websites need to be fast - let’s see why that’s so important and
        how you can find your need for speed.
      </p>

      <img src={img1} alt="Traffic" title="Website speed" />
      <p>
        Source:{' '}
        <a
          href="https://pixabay.com/photos/traffic-highway-lights-night-speed-332857/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Pixabay
        </a>
      </p>

      <h2>Why Website Speed Is So Important</h2>

      <p>
        There are two main reasons why website speed can have a huge impact on your reach across the
        web and, ultimately, your revenue. The first is user experience, or UX, which is to do with
        how users directly experience your site. The second is search engine optimization (SEO), or
        how users find your site in the first place. We’ll see how the speed of your site can have a
        huge impact on important website metrics, and then we’ll get you up to speed on website
        speed.
      </p>

      <h3>Speed Will Boost Your User Experience</h3>

      <p>
        If anyone knows what web users want, it’s Google - and their research has shown that website
        speed has a{' '}
        <a
          href="https://ai.googleblog.com/2009/06/speed-matters.html"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          direct impact on user’s happiness, productivity and the time they spend on your site
        </a>
        . Elsewhere, research has shown that{' '}
        <a
          href="https://www.section.io/blog/page-load-time-bounce-rate/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          one in ten internet users will abandon your site within two seconds
        </a>
        , which gives you an incredibly narrow window to wow your visitors.
      </p>

      <p>
        And the way mobile technology has changed browsing habits means more people are visiting
        your site from phones and tablets -{' '}
        <a
          href="https://developer.akamai.com/blog/2016/09/14/mobile-load-time-user-abandonment"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          over half of mobile browsers won’t wait three seconds for a site to load
        </a>
        . You can’t afford to ignore the growing mobile market, so speeds need to be optimized for
        mobile browsers as well as desktop.
      </p>

      <h3>Speed Will Aid Your SEO Website Metrics</h3>

      <p>
        SEO is essential for businesses invested in any way in the online world. Getting your page
        higher on the search engine ranking means more people finding your site, visiting your store
        or purchasing your products and services. Because most web users ditch after just a few
        seconds, a laggy site will impact a number of important website metrics such as bounce rate
        and dwell time - impacting your SEO.
      </p>

      <p>
        What’s more, since 2018{' '}
        <a
          href="https://searchengineland.com/google-speed-update-page-speed-will-become-ranking-factor-mobile-search-289904#:~:text=Starting%20in%20July%202018%2C%20Google,in%20their%20mobile%20search%20results.&text=Google%20today%20announced%20a%20new,queries%2C%20Google%20reiterated%20to%20us."
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Google has been using mobile page speed as a ranking factor
        </a>{' '}
        - meaning slow loading pages get buried under snappy sites. Google incorporates page speed
        as a website metric because they know how important loading times are to user experience. UX
        drives SEO metrics, and SEO metrics drive traffic to your site. Website speed thus plays a
        critical role in this process at every step of the way.
      </p>
      <h2>How To Check Your Website’s Speed</h2>
      <img
        src={img2}
        alt="Google’s PageSpeed Insights"
        title="Google’s PageSpeed Insights - stats"
      />
      <p>
        If you’re still not sure your website needs a speed injection then there are plenty of tools
        you can use to diagnose every aspect of page loading times. As part of their broader
        analytics package, Google offers{' '}
        <a
          href="https://developers.google.com/speed/pagespeed/insights/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          PageSpeed Insights
        </a>{' '}
        which gives you a clear summary of how your site performs. Further down the page,
        opportunities and diagnostics are identified to help you solve site speed issues.
      </p>
      <img
        src={img3}
        alt="Google’s PageSpeed Insights"
        title="Google’s PageSpeed Insights - stats - 2"
      />
      <p>
        Another online tool to check out is{' '}
        <a
          href="https://www.pingdom.com/website-monitoring"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          Pingdom
        </a>
        . Pingdom is easy to use for anyone without a background in data or analytics and by
        tracking historical performance you can see how your site is improving through the measures
        you’ve taken.
      </p>

      <p>
        “No site is operating at a perfect speed,” says Dorothy Schneider, a content writer at
        Writinity. “As a website metric speed is more important than ever so optimizing your site to
        operate faster should be a huge goal from both a UX, SEO and revenue perspective.” Let’s
        find out how to speed your site up.
      </p>
      <h2>Six Ways To Speed Your Website Up Fast</h2>
      <h3>1. Update Your Server</h3>
      <p>
        {' '}
        Before you go to any crazy lengths to speed up your site, you need to check your server is
        up to scratch. This is one of the simplest solutions to a slow website. Site speed can vary
        dramatically depending on how it’s hosted and often we start out taking cheap options -
        ending up sharing bandwidth and choking out speed.
      </p>

      <p>
        Web hosting can be <i>shared</i>, <i>dedicated</i>, or VPS and it’s important to understand
        the difference. Shared hosting means that you’re not the only site on your server - the cost
        is spread amongst a number of sites, so it’s cheaper. Dedicated hosting gives you your own
        server - a real, physical thing that’s dedicated to you. This gives you maximum speed, but
        it’s extremely expensive and probably overkills. Moving your hosting plan from shared to VPS
        (a virtual private server) can have a huge difference, however - your hosting is distributed
        over a multitude of servers, giving you upgraded speeds.
      </p>
      <h3>2. Rethink Your Images</h3>
      <p>
        On average,{' '}
        <a
          href="https://httparchive.org/reports/state-of-images"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          around 60% of a website’s bulk is made up of images
        </a>{' '}
        so if you can trim the fat off your images you’ll be downsizing your website and speeding it
        up in the process. Of course, stripping your site back to a wall of text isn’t going to do
        user experience or website metrics any favors - images are an important part of the vitality
        of your site - so cropping and compression are the name of the game here.
      </p>

      <p>
        You can use PhotoShop or{' '}
        <a
          href="https://guides.lib.umich.edu/c.php?g=282942&p=1888162"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          a free tool like GIMP
        </a>{' '}
        to resize your images before you upload them. To make life even easier, if you’re running a
        WordPress site there are a variety of plugins that will remove any unnecessary information
        in your pics - Smush, for example, automatically does this as you upload them, ensuring your
        website is lean and speedy.
      </p>
      <img src={img4} alt="GIMP resizing" title="Scale Image" />
      <p>Source: University of Michigan Library</p>
      <h3>3. Taking Requests</h3>
      <p>
        One of the reasons that sites can be slow to load is because of the number of requests being
        made each time it loads. These requests are made by HTTP (Hypertext Transfer Protocol) and
        each request represents a journey to the server and back, each time returning with a piece
        of your site. Identifying how many HTTP requests your site makes will enable you to identify
        issues with loading time - you can use Pingdom to analyze requests and sort them by the time
        they took. Reducing requests any way you can speed up your site so any slow request you can
        eliminate will speed your site up.
      </p>
      <h3>4. Leveraging The Cache</h3>
      <p>
        {' '}
        Caching is a mechanism that allows web users to bypass a proportion of the requests a
        browser makes by HTTP when it visits a site - it does so by saving elements of the site from
        previous visits, so repeat requests are eliminated. “Caching only works for users who have
        visited your site before, and been able to cache its elements,” warns Andrew Merkson, a
        marketing blogger at Draft beyond, “but for returning visitors, it can speed up loading time
        significantly.”
      </p>

      <p>
        For all caches there’s an expiry date that can be adjusted so they remain for longer - jor
        jpg files the default is one week. Adjusting the “expires header” will ensure caches will
        remain for longer and people{' '}
        <a
          href="https://premio.io/10-best-plugins-for-speeding-up-your-website-loading-time/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          get increased loading speeds
        </a>{' '}
        on your site. WordPress plugins such as{' '}
        <a
          href="https://wordpress.org/plugins/w3-total-cache/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          W3 Total Cache
        </a>{' '}
        or{' '}
        <a
          href="https://wordpress.org/plugins/wp-super-cache/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          WP Super Cache
        </a>{' '}
        can optimize the caching process for WordPress sites, and for non-WordPress sites, you can
        explore{' '}
        <a
          href="https://blog.stackpath.com/glossary-content-caching/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          intelligent content caching
        </a>{' '}
        as a way of bringing your site up to speed.
      </p>
      <img src={img5} alt="W3 Total Cache" title="Leveraging The Cache" />
      <h3>5. WordPress Plugins And External Scripts</h3>
      <p>
        {' '}
        If you’re running a WordPress site -{' '}
        <a
          href="https://www.whoishostingthis.com/compare/wordpress/stats/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          and over a third of website owners are
        </a>{' '}
        - then you’ll likely be packing your site with plugins to provide analytics,{' '}
        <a
          href="https://wordable.io/how-to-grow-an-email-list/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          manage email lists
        </a>{' '}
        or track website metrics. However, a multitude of plugins will slow your loading times down,
        and old plugins that aren’t kept up to date will have the biggest impact.
      </p>

      <p>
        Outdated plugins are a security risk to your site as well as a drain on your loading speeds.
        Spring clean your plugins two to three times a year to make sure your plugins aren’t holding
        you back.
      </p>

      <p>
        For non-WordPress sites, external scripts can function in the same way, bloating your page
        and choking loading speed. Those extra features, such as external fonts and Facebook “like”
        boxes, written in JavaScript, add up to slow your site down. Pingdom will inform you which
        of your scripts are slowest to load so you can take action.
      </p>
      <h3>6. Optimizing JavaScript And CSS Files</h3>
      <p>
        In much the same way that images can be compressed to reduce the size - and loading speed of
        your page - your site’s files can be compressed. By applying minification and gzipping to
        your JavaScript and CSS files you can slim your site down without removing any content that
        your users love.
      </p>

      <p>
        Minification - for which there are a few great{' '}
        <a
          href="https://wordpress.org/plugins/bwp-minify/"
          target="_blank"
          rel="noopener noreferrer nofollow"
        >
          WordPress Plugins
        </a>{' '}
        - will shrink your code by removing semicolons, comments, and whitespace giving your
        streamlined CSS. Gzipping compresses your files on the server level.
      </p>
      <h2>Up To Speed to Improve Webiste Metrics</h2>
      <p>
        A slow website suppresses important website metrics, chokes your SEO, and puts up a barrier
        between you and your users. Use these tips to get your website up to speed and run
        diagnostics on a regular basis. Website speed isn’t a one-time fix - you’ll need to keep on
        top of external scripts, image size, and plugins. Now you have the tools to do so.
      </p>
    </Post>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Why Website Speed Matters - And How To Supercharge it (6-Points Checklist)',
  url: '/blog/website-speed/',
  description: `Learn more about website speed. Implement tips from our 6-points checklist and increase your website speed fast`,
  author,
  img,
  imgSocial: img,
  date: '2020-11-27',
  category: '',
  group: 'Analytics',
  timeToRead: 8,
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    img={frontmatter.img}
    imgSocial={process.env.GATSBY_HOST + frontmatter.img}
    group={frontmatter.group}
    timeToRead={frontmatter.timeToRead}
  />
);
